import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.replace-all.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { copyIcon } from "@debionetwork/ui-icons";
import { getOrderDetail } from "@/common/lib/api";
import { getRatingService } from "@/common/lib/api";
import { queryDnaSamples, queryGeneticAnalysisByGeneticAnalysisTrackingId } from "@debionetwork/polkadot-provider";
import { mapState } from "vuex";
import getEnv from "@/common/lib/utils/env";
import { formatUSDTE } from "@/common/lib/price-format.js";
import { getMenstrualSubscriptionById, getMenstrualSubscriptionPrices } from "@/common/lib/polkadot-provider/query/menstrual-subscription";

// NOTE: Use anchor tag with "noreferrer noopener nofollow" for security
let timeout;
const anchor = document.createElement("a");
anchor.target = "_blank";
anchor.rel = "noreferrer noopener nofollow";
export default {
  name: "CustomerPaymentDetails",
  data: () => ({
    copyIcon,
    messageError: null,
    rewardPopup: false,
    payment: {},
    txHash: null,
    formatUSDTE
  }),
  computed: {
    ...mapState({
      api: state => state.substrate.api,
      rating: state => state.rating.rate,
      lastEventData: state => state.substrate.lastEventData,
      web3: state => state.metamask.web3
    }),
    computeProviderName() {
      var _this$payment$genetic, _this$payment2, _this$payment2$geneti;
      if (this.payment.section === "order") {
        var _this$payment$lab_inf, _this$payment, _this$payment$lab_inf2;
        return (_this$payment$lab_inf = (_this$payment = this.payment) === null || _this$payment === void 0 ? void 0 : (_this$payment$lab_inf2 = _this$payment.lab_info) === null || _this$payment$lab_inf2 === void 0 ? void 0 : _this$payment$lab_inf2.name) !== null && _this$payment$lab_inf !== void 0 ? _this$payment$lab_inf : "Unknown Provider";
      } else if (this.payment.section === "menstrual-subscription") {
        return "DeBio Network";
      }
      return (_this$payment$genetic = (_this$payment2 = this.payment) === null || _this$payment2 === void 0 ? void 0 : (_this$payment2$geneti = _this$payment2.genetic_analyst_info) === null || _this$payment2$geneti === void 0 ? void 0 : _this$payment2$geneti.name) !== null && _this$payment$genetic !== void 0 ? _this$payment$genetic : "Unknown Provider";
    },
    computeDetailsTitle() {
      var _this$payment3, _this$payment4, _this$payment5;
      return ((_this$payment3 = this.payment) === null || _this$payment3 === void 0 ? void 0 : _this$payment3.status) === "Paid" ? "[ ".concat((_this$payment4 = this.payment) === null || _this$payment4 === void 0 ? void 0 : _this$payment4.status, " Order ] - Thank you for your order") : "".concat((_this$payment5 = this.payment) === null || _this$payment5 === void 0 ? void 0 : _this$payment5.status, " Order");
    },
    hasPaymentDetails() {
      var _Object$keys;
      return (_Object$keys = Object.keys(this.payment)) === null || _Object$keys === void 0 ? void 0 : _Object$keys.length;
    },
    computeTotalPrices() {
      var _this$payment6, _this$payment6$additi, _this$payment7, _this$payment8, _this$payment9, _this$payment10, _this$payment11, _this$payment12;
      if (!((_this$payment6 = this.payment) !== null && _this$payment6 !== void 0 && (_this$payment6$additi = _this$payment6.additional_prices) !== null && _this$payment6$additi !== void 0 && _this$payment6$additi.length)) return this.formatPrice((_this$payment7 = this.payment) === null || _this$payment7 === void 0 ? void 0 : _this$payment7.prices[0].value, (_this$payment8 = this.payment) === null || _this$payment8 === void 0 ? void 0 : _this$payment8.currency);
      return this.formatPrice((_this$payment9 = this.payment) === null || _this$payment9 === void 0 ? void 0 : _this$payment9.prices[0].value, (_this$payment10 = this.payment) === null || _this$payment10 === void 0 ? void 0 : _this$payment10.currency) + this.formatPrice((_this$payment11 = this.payment) === null || _this$payment11 === void 0 ? void 0 : _this$payment11.additional_prices[0].value, (_this$payment12 = this.payment) === null || _this$payment12 === void 0 ? void 0 : _this$payment12.currency);
    },
    computeRefundedValue() {
      var _this$payment13, _this$payment14, _this$payment15, _this$payment16;
      return ((_this$payment13 = this.payment) === null || _this$payment13 === void 0 ? void 0 : _this$payment13.status) === "Refunded" ? "".concat(this.formatPrice((_this$payment14 = this.payment) === null || _this$payment14 === void 0 ? void 0 : _this$payment14.prices[0].value, (_this$payment15 = this.payment) === null || _this$payment15 === void 0 ? void 0 : _this$payment15.currency), " ").concat((_this$payment16 = this.payment) === null || _this$payment16 === void 0 ? void 0 : _this$payment16.currency) : "-";
    }
  },
  beforeMount() {
    if (!this.$route.params.id) this.$router.push({
      name: "customer-payment-history"
    });
  },
  async created() {
    const menstrualSubscription = await getMenstrualSubscriptionById(this.api, this.$route.params.id);
    await this.fetchMensSubscriptionDetails(menstrualSubscription);
    if (!menstrualSubscription) await this.fetchDetails();
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler: async function () {
        await this.fetchDetails();
      }
    },
    lastEventData: async function (e) {
      if (e.section === "geneticTesting" || e.section === "orders") await this.fetchDetails();
    }
  },
  methods: {
    async fetchDetails() {
      try {
        let data;
        let rating;
        let isNotGAOrders = false;
        const dataPayment = await getOrderDetail(this.$route.params.id);
        this.txHash = dataPayment.blockMetaData.blockHash;
        const classes = Object.freeze({
          PAID: "success--text",
          UNPAID: "warning--text",
          REFUNDED: "secondary--text",
          CANCELLED: "error--text",
          FULFILLED: "info--text",
          RESULTREADY: "success--text",
          INPROGRESS: "info--text",
          REGISTERED: "success--text",
          WETWORK: "secondary--text",
          REJECTED: "error--text",
          QUALITYCONTROLLED: "info--text"
        });
        const parseDate = date => {
          return new Date(parseInt(date.replaceAll(",", ""))).toLocaleDateString("en-GB", {
            day: "numeric",
            month: "short",
            year: "numeric"
          });
        };
        if (Object.values(dataPayment).length) {
          var _data, _data2, _data3, _data4;
          if (!Object.hasOwnProperty.call(dataPayment, "genetic_analysis_tracking_id")) {
            try {
              isNotGAOrders = true;
              rating = await getRatingService(dataPayment.service_id);
              data = await queryDnaSamples(this.api, dataPayment.dna_sample_tracking_id);
            } catch (error) {}
          } else {
            try {
              data = await queryGeneticAnalysisByGeneticAnalysisTrackingId(this.api, dataPayment.genetic_analysis_tracking_id);
            } catch (error) {}
          }
          this.payment = isNotGAOrders ? {
            ...dataPayment,
            section: "order",
            formated_id: "".concat(dataPayment.id.substr(0, 3), "...").concat(dataPayment.id.substr(dataPayment.id.length - 4)),
            test_status: (_data = data) === null || _data === void 0 ? void 0 : _data.status.replace(/([A-Z]+)/g, " $1").trim(),
            test_status_class: classes[(_data2 = data) === null || _data2 === void 0 ? void 0 : _data2.status.toUpperCase()],
            rating,
            status_class: classes[dataPayment.status.toUpperCase()],
            created_at: parseDate(dataPayment.created_at)
          } : {
            ...dataPayment,
            formated_id: "".concat(dataPayment.id.substr(0, 3), "...").concat(dataPayment.id.substr(dataPayment.id.length - 4)),
            status_class: classes[dataPayment.status.toUpperCase()],
            test_status: (_data3 = data) === null || _data3 === void 0 ? void 0 : _data3.status.replace(/([A-Z]+)/g, " $1").trim(),
            test_status_class: classes[(_data4 = data) === null || _data4 === void 0 ? void 0 : _data4.status.toUpperCase()],
            genetic_analyst_info: {
              ...dataPayment.genetic_analyst_info,
              name: "".concat(dataPayment.genetic_analyst_info.first_name, " ").concat(dataPayment.genetic_analyst_info.last_name)
            },
            section: "orderGA",
            created_at: parseDate(dataPayment.created_at)
          };
        }
      } catch (e) {
        if (e.response.status === 404) this.messageError = "Oh no! We can't find your selected order. Please select another one";else this.messageError = "Something went wrong. Please try again later";
      }
    },
    async fetchMensSubscriptionDetails(detail) {
      const parseDate = date => {
        return new Date(parseInt(date.replaceAll(",", ""))).toLocaleDateString("en-GB", {
          day: "numeric",
          month: "short",
          year: "numeric"
        });
      };
      this.payment = {
        id: detail.id,
        status: detail.paymentStatus.toUpperCase(),
        formated_id: "".concat(detail.id.substr(0, 3), "...").concat(detail.id.substr(detail.id.length - 4)),
        section: "menstrual-subscription",
        service_info: {
          name: "Menstrual Calendar ".concat(detail.duration, " Subscription")
        },
        created_at: parseDate(detail.createdAt),
        status_class: "success--text",
        duration: detail.duration,
        currency: detail.currency,
        price: this.formatPrice((await getMenstrualSubscriptionPrices(this.api, detail.duration, detail.currency)).amount)
      };
    },
    async handleCopy() {
      var _this$payment17;
      await navigator.clipboard.writeText((_this$payment17 = this.payment) === null || _this$payment17 === void 0 ? void 0 : _this$payment17.id);
      this.payment.formated_id = "Copied!";
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        this.payment.formated_id = "".concat(this.payment.id.slice(0, 3), "...").concat(this.payment.id.slice(-4));
      }, 1000);
    },
    formatPrice(price, currency) {
      let unit;
      currency === "USDT" || currency === "USDTE" ? unit = "mwei" : unit = "ether";
      const formatedPrice = this.web3.utils.fromWei(String(price.replaceAll(",", "")), unit);
      return parseFloat(formatedPrice);
    },
    async handleCTA() {
      var _this$payment18;
      if (((_this$payment18 = this.payment) === null || _this$payment18 === void 0 ? void 0 : _this$payment18.status) === "Unpaid") {
        this.$router.push({
          name: "customer-request-test-checkout",
          params: {
            id: this.$route.params.id
          }
        });
        return;
      }
      anchor.href = "".concat(getEnv("VUE_APP_OCTOPUS_EXPLORER"), "/blocks/").concat(this.txHash);
      anchor.click();
    }
  }
};